import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {ListOptions} from "src/primitives/controls/ListOptions.jsx";
import {useGetColumns} from "src/api/tableColumns/useGetColumns.js";
import {arrayToMap} from "src/utils/misc.js";


function ColumnList({columns, isSelected, onSelect}) {
    const options = columns.map(({name}) => {
        return {key: name, label: name};
    });

    return (
        <div className="min-w-52 max-h-96 overflow-y-scroll">
            <ListOptions
                options={options}
                isSelected={isSelected}
                onSelect={onSelect}
            />
        </div>
    );
}

ColumnList.propTypes = {
    columns: PropTypes.array,
    isSelected: PropTypes.func,
    onSelect: PropTypes.func
};

export function SelectColumnOverlay({columns, children, selected, onSelect, isSelected}) {
    const derivedIsSelected = useCallback((key) => {
        return isSelected ? isSelected(key) : key === selected;
    }, [selected, isSelected]);

    return (
        <Overlay
            content={(
                <ColumnList
                    columns={columns}
                    isSelected={derivedIsSelected}
                    onSelect={onSelect}
                />
            )}
        >
            {children}
        </Overlay>
    );
}

SelectColumnOverlay.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node,
    selected: PropTypes.string,
    isSelected: PropTypes.func,
    onSelect: PropTypes.func
};

export function SelectColumn({tableId, children, selected, onSelect, isSelected}) {
    const {columns, loading, error} = useGetColumns(tableId);
    const columnMap = useMemo(() => {
        return arrayToMap(columns, "name");
    }, [columns]);

    const handleSelect = useCallback((key) => {
        const column = key ? columnMap[key] : null;
        onSelect(column);
    }, [onSelect, columnMap]);

    if (error) {
        return <div className="text-red-500">Failed to fetch columns</div>;
    }

    if (loading) {
        return <div className="text-gray-500">Loading...</div>;
    }

    return (
        <SelectColumnOverlay
            columns={columns}
            selected={selected}
            onSelect={handleSelect}
            isSelected={isSelected}
        >
            {children}
        </SelectColumnOverlay>
    );
}

SelectColumn.propTypes = {
    tableId: PropTypes.string.isRequired,
    children: PropTypes.node,
    selected: PropTypes.string,
    isSelected: PropTypes.func,
    onSelect: PropTypes.func
};

import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useGetPredictionModels} from "src/api/predictionModels/useGetPredictionModels.js";
import {useCreatePredictionModel} from "src/api/predictionModels/useCreatePredictionModel.js";
import {Button} from "src/primitives/button.jsx";
import {Link, Navigate} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {formatTimestamp} from "src/utils/formatting.js";
import {sortByDate} from "src/utils/sorting.js";
import {Title} from "src/primitives/title.jsx";


export function PredictionModels({tableId}) {
    const getLink = useGetLink();
    const [doCreate, {loading: creating, result}] = useCreatePredictionModel(tableId);
    const {models, loading} = useGetPredictionModels(tableId);

    const handleCreate = useCallback(() => {
        doCreate();
    }, [doCreate]);

    if (loading) {
        return (
            <div className="full flex justify-center items-center">
                <Spinner/>
            </div>
        );
    }

    if (result?.model?.id) {
        return (
            <Navigate
                to={getLink(LINKS.tablePredictionModel(tableId, result.model.id))}
                replace={true}
            />
        );
    }

    const modelsSorted = sortByDate(models, "createdAt", "desc");

    return (
        <div>
            <div className="mb-4">
                <Button
                    onClick={handleCreate}
                    isLoading={creating}
                    theme="black"
                >
                    Create prediction model
                </Button>
            </div>
            {modelsSorted.length > 0 && (
                <div
                    className="border-b border-black/10 pt-4 pb-2 my-4 w-112"
                >
                    <Title size="xs">
                        Existing models
                    </Title>
                </div>
            )}
            <div className="flex flex-col gap-2">
                {modelsSorted.map((item) => {
                    return (
                        <Link
                            to={getLink(LINKS.model.train(tableId, item.id))}
                            key={item.id}
                            className="block mb-4 p-4 rounded-md border border-neutral-500 w-112 hover:bg-black-2"
                        >
                            <div className=" text-sm flex flex-col gap-2">
                                <div className="text-xs monospace text-neutral-600">
                                    ID: {item.id}
                                </div>
                                <div className="text-xs monospace text-neutral-600">
                                    Created: {formatTimestamp(item.createdAt)}
                                </div>
                                <div>
                                    {item.title}
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

PredictionModels.propTypes = {
    tableId: PropTypes.string.isRequired
};

import React from "react";
import PropTypes from "prop-types";
import {CopyCode} from "src/primitives/Terminal/CopyCode.jsx";
import {coalesce} from "src/utils/misc.js";
import {getPredictBaseUrl} from "../../utils.js";


export function PredictionRequest({modelId, features, featureData, apiKey}) {
    const baseUrl = getPredictBaseUrl(modelId);
    const featuresNames = features.map(({name}) => name);
    const featuresString = featuresNames.map((name) => `"${name}": "${coalesce(featureData[name], "")}"`).join(",\n\t");
    const codeExample = `import requests

url = "${baseUrl}"
payload = [{
    ${featuresString}
}, ]
headers = {
    "X-Api-Key": "${apiKey}"
}

response = requests.post(url, json=payload, headers=headers)
print(response.json())
`;

    return (
        <div>
            <div className="bg-neutral-950 rounded-md max-w-192">
                <CopyCode
                    code={codeExample}
                    language="python"
                />
            </div>
        </div>
    );
}


PredictionRequest.propTypes = {
    modelId: PropTypes.string.isRequired,
    apiKey: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    })),
    featureData: PropTypes.object
};

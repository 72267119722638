import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";


export const DataSidebarContext = createContext(null);


export const ManageDataSidebarContext = createContext(null);


export function DataSidebarContextProvider({children}) {
    const [visible, show, close, toggle] = useBooleanState(false);

    return (
        <ManageDataSidebarContext.Provider value={{show, close, toggle}}>
            <DataSidebarContext.Provider value={visible}>
                {children}
            </DataSidebarContext.Provider>
        </ManageDataSidebarContext.Provider>
    );
}

DataSidebarContextProvider.propTypes = {
    children: PropTypes.element
};

export function useDataSidebarState() {
    return useContext(DataSidebarContext);
}

export function useManageDataSidebar() {
    return useContext(ManageDataSidebarContext);
}

import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

export const ContentEditableDiv = ({onChange, ...props}) => {
    const contentEditableRef = useRef(null);

    useEffect(() => {
        const handleInput = () => {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const clonedRange = range.cloneRange();
            clonedRange.selectNodeContents(contentEditableRef.current);
            clonedRange.setEnd(range.endContainer, range.endOffset);
            // Store cursor position in a data attribute
            contentEditableRef.current.dataset.cursorPosition = clonedRange.toString().length;

            // Update the content of the editable div
            onChange(contentEditableRef.current.textContent);
        };

        const restoreCursor = () => {
            const cursorPosition = parseInt(contentEditableRef.current.dataset.cursorPosition, 10);
            const textNode = contentEditableRef.current.firstChild;

            if (textNode) {
                const range = document.createRange();
                range.setStart(textNode, cursorPosition);
                range.setEnd(textNode, cursorPosition);

                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        };

        const div = contentEditableRef.current;
        div.addEventListener("input", handleInput);
        div.addEventListener("focus", restoreCursor);

        return () => {
            div.removeEventListener("input", handleInput);
            div.removeEventListener("focus", restoreCursor);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={contentEditableRef}
            contentEditable={true}
            suppressContentEditableWarning={true}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
        />
    );
};

ContentEditableDiv.propTypes = {
    onChange: PropTypes.func
};

import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {Navigate} from "react-router-dom";
import {useStartChat} from "src/api/chat/useStartChat.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {ContentEditableDiv} from "src/primitives/controls/ContentEditable.jsx";
import {Title} from "src/primitives/title.jsx";
import {useGetChatSessions} from "src/api/chat/useGetChatSessions.js";
import {overrideSystemHandling} from "src/utils/system.js";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {ExistingSessions} from "./components/ExistingSessions/index.jsx";


export function StartNewChatPage({tableId}) {
    const {sessions, loading: loadingSessions} = useGetChatSessions(tableId);
    const getLink = useGetLink();
    const [value, setValue] = useState("");
    const [startSession, {loading, error, sessionId}] = useStartChat(tableId);

    const handleSubmit = useCallback((event) => {
        overrideSystemHandling(event);
        if (!value) {
            return;
        }
        startSession(value).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [startSession, value]);

    useKeyPress(handleSubmit, ["Enter"], {metaKey: true});

    if (sessionId) {
        const params = {q: value};
        return (
            <Navigate
                to={getLink(LINKS.tableChatSession(tableId, sessionId), params)}
            />
        );
    }

    return (
        <div className="full overflow-auto">
            <div className="flex flex-col gap-4 w-112 mx-auto pt-40 pb-20">
                <form className="flex flex-col gap-2 mb-8" onSubmit={handleSubmit}>
                    <Title>Start new chat</Title>
                    <div className="relative">
                        <ContentEditableDiv
                            className="rounded-md p-2 text-neutral-800 border border-neutral-200 min-h-36"
                            onChange={setValue}
                        />
                        {value === "" ? (
                            <div className="absolute inset-0 p-2 text-neutral-500 inert">
                                Ask me anything...
                            </div>
                        ) : null}
                    </div>
                    <Button
                        isLoading={loading}
                        theme="secondary"
                        isDisabled={!value}
                    >
                        <span>
                            Start new session
                        </span>
                    </Button>

                    {error && (
                        <div>
                            Failed to start new session
                        </div>
                    )}
                </form>

                <ExistingSessions sessions={sessions} loading={loadingSessions}/>
            </div>
        </div>
    );
}


StartNewChatPage.propTypes = {
    tableId: PropTypes.string.isRequired
};

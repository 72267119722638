import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiMinus, FiPlus} from "react-icons/fi";
import {cva} from "cva";


const wrapperClasses = cva({
    base: "w-full flex flex-col gap-2 transition-all",
    variants: {
        size: {
            xs: "!gap-1"
        },
        theme: {}
    },
    defaultVariants: {}
});


const buttonClasses = cva({
    base: "w-full flex items-center justify-between border-t",
    variants: {
        size: {
            xs: "py-2 px-2",
            small: "py-4 px-2",
            medium: "py-4 px-2",
            large: "py-4 px-2"
        },
        theme: {
            dark: "border-white/10 hover:bg-white-2",
            light: "border-black/10 hover:bg-black-2"
        }
    },
    defaultVariants: {
        size: "small",
        theme: "dark"
    }
});


export function Accordion({title, children, size, theme, defaultState}) {
    const [isOpen, setIsOpen] = useState(defaultState);
    const toggleOpen = useCallback(() => {
        setIsOpen((prevState) => !prevState);
    }, [setIsOpen]);

    return (
        <Wrapper className={wrapperClasses({size})}>
            <button
                type="button"
                className={buttonClasses({size, theme})}
                onClick={toggleOpen}
            >
                <Title size={size} style={{margin: 0}}>
                    {title}
                </Title>
                <div>
                    <IconWrapper
                        icon={isOpen ? <FiMinus/> : <FiPlus/>}
                        size="small"
                    />
                </div>
            </button>

            <div
                className="py-2 px-2 mb-4 max-w-192"
                style={{display: isOpen ? "block" : "none"}}
            >
                {children}
            </div>
        </Wrapper>
    );
}

Accordion.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    size: PropTypes.oneOf(["xs", "small", "medium", "large"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    defaultState: PropTypes.bool
};

Accordion.defaultProps = {
    size: "small",
    defaultState: false
};


const Wrapper = styled.div`
`;



export function formatSQL(query) {
    // Define SQL keywords that we want to format around
    const keywords = [
        "SELECT", "FROM", "WHERE", "GROUP BY",
        "HAVING", "ORDER BY", "LIMIT", "JOIN",
        "LEFT JOIN", "RIGHT JOIN", "INNER JOIN", "OUTER JOIN", "ON"
    ];

    // Initial cleanup of spaces
    let formattedQuery = query
        .replace(/\s+/g, " ") // Replace multiple spaces with a single space
        .trim(); // Trim leading and trailing whitespace

    // Add line breaks before main SQL clauses
    keywords.forEach((keyword) => {
        const regex = new RegExp(`\\b${keyword}\\b`, "gi"); // Match whole word case-insensitively
        formattedQuery = formattedQuery.replace(regex, `\n${keyword}`);
    });

    // Handling commas in SELECT and other clauses for column lists
    formattedQuery = formattedQuery
        .replace(/,\s*/g, ",\n    ") // New line with indentation after commas
        .replace(/\s+AS\s+/gi, " AS ") // Remove extra spaces before and after AS
        .replace(/\nON/g, " ON") // Keep ON on the same line
        .replace(/\nAND/g, " AND") // Remove new line before AND for better readability in WHERE clause
        .replace(/\nOR/g, " OR"); // Remove new line before OR for better readability in WHERE clause

    // Add line break and indent for SELECT keyword
    formattedQuery = formattedQuery.replace(/\nSELECT\s+/gi, "\nSELECT\n    ");

    // Ensure proper line break and indentation before ORDER BY
    formattedQuery = formattedQuery.replace(/\s+ORDER BY\s+/gi, "\nORDER BY\n    ");

    return formattedQuery.trim();
}


function splitSelectExpressions(selectPart) {
    const expressions = [];
    let currentExpr = "";
    let parenCount = 0;
    let inQuotes = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const char of selectPart) {
        if (char === "(" && !inQuotes) {
            parenCount++;
        } else if (char === ")" && !inQuotes) {
            parenCount--;
        } else if (char === "'" || char === "\"") {
            inQuotes = !inQuotes;
        }

        if (char === "," && parenCount === 0 && !inQuotes) {
            expressions.push(currentExpr.trim());
            currentExpr = "";
        } else {
            currentExpr += char;
        }
    }

    if (currentExpr.trim()) {
        expressions.push(currentExpr.trim());
    }

    return expressions;
}


export function extractColumnOrder(sqlQuery) {
    // Remove any newlines and extra spaces
    const cleanQuery = sqlQuery.replace(/\s+/g, " ").trim();

    // Extract the SELECT part of the query
    const selectMatch = cleanQuery.match(/SELECT(.*?)FROM/i);
    if (!selectMatch) {
        throw new Error("Invalid SQL query: SELECT statement not found");
    }

    const selectPart = selectMatch[1].trim();

    // Split the select part into individual column expressions
    const columnExpressions = splitSelectExpressions(selectPart);

    // Process each column expression to get the column name or alias
    const columnNames = columnExpressions.map((expr) => {
        const trimmedExpr = expr.trim();
        const asMatch = trimmedExpr.match(/AS\s+(\w+)$/i);
        if (asMatch) {
            // If there's an alias, use it
            return asMatch[1];
        }
        // Otherwise, use the expression itself or extract the column name
        const columnMatch = trimmedExpr.match(/^(.*?)(?:\s+AS\s+.+)?$/i);
        return columnMatch ? columnMatch[1].trim() : trimmedExpr;
    });

    return columnNames.map((name) => name.replace(/"/g, ""));
}

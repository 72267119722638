import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


export const GET_CHAT_SESSION_QUERY = gql`
    query GetChatSession($sessionId: String!) {
        chatSession (sessionId: $sessionId) {
            id
            createdAt
            updatedAt
            title
            history
            data {
              id
              createdAt
              sessionId
              toolCallId
              name
              query
              data
            }
        }
    }
`;


export function useGetChatSession(sessionId) {
    const {data, loading, error, refetch} = useQuery(GET_CHAT_SESSION_QUERY, {variables: {sessionId}});

    const session = useMemo(() => {
        return data?.chatSession;
    }, [data]);

    return {
        session, loading, error, refetch
    };
}

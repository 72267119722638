import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation DeletePredictionModel($modelId: String!) {
        deletePredictionModel(modelId: $modelId) {
            ok
            errorCode
            modelId
        }
    }
`;

function removePredictionModelFromCache(cache, response) {
    const {ok, modelId} = response.data.deletePredictionModel;
    if (!ok) {
        return;
    }
    const model = {
        id: modelId,
        __typename: "PredictionModel"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}


export function useDeletePredictionModel(modelId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {modelId},
            update(cache, response) {
                removePredictionModelFromCache(cache, response);
            }
        }).then((response) => {
            return response.data.deletePredictionModel;
        });
    }, [mutation, modelId]);

    return [doMutate, {loading, error, result: data?.deletePredictionModel}];
}

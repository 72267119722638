import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {formatNumber} from "src/utils/formatting.js";


export function ModelResultTable({result}) {
    const data = [{
        name: "R2",
        result: result?.r2
    }, {
        name: "Mean Squared Error",
        result: result?.mse
    }, {
        name: "Mean Absolute Error",
        result: result?.mae
    }, {
        name: "Mean Absolute Percentage Error",
        result: result?.mape
    }];
    const columns = [{
        key: "name",
        header: "Measurement"
    }, {
        key: "result",
        header: "Result",
        render: (value) => formatNumber(value, 4)
    }];

    return (
        <div className="my-4 w-96">
            <SimpleTable
                data={data}
                columns={columns}
            />
        </div>
    );
}

ModelResultTable.propTypes = {
    result: PropTypes.shape({
        r2: PropTypes.number,
        mse: PropTypes.number,
        mae: PropTypes.number,
        mape: PropTypes.number
    })
};

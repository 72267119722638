import {useCallback, useMemo} from "react";
import {useSearchParams} from "react-router-dom";

export const WORKSPACE_PARAM_KEY = "wid";
export const WORKSPACE_HEADER_KEY = "X-Workspace-ID";

export function useSelectedWorkspace() {
    const [searchParams, setSearchParams] = useSearchParams();
    const workspaceId = searchParams.get(WORKSPACE_PARAM_KEY);

    const setWorkspaceId = useCallback((wid) => {
        setSearchParams({[WORKSPACE_PARAM_KEY]: wid});
    }, [setSearchParams]);

    const workspaceIdMemo = useMemo(() => workspaceId, [workspaceId]);

    return [workspaceIdMemo, setWorkspaceId];
}

export function getUrlParams() {
    const params = {};
    const paramsString = window.document.URL.split("?")[1];
    if (!paramsString) {
        return params;
    }

    paramsString.split("&").forEach((el) => {
        const [key, value] = el.split("=");
        params[key.trim()] = decodeURIComponent(value.trim());
    });

    return params;
}

export function getWorkspaceId() {
    const params = getUrlParams();

    return params[WORKSPACE_PARAM_KEY];
}

export function useRemoveSearchParam() {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback((key) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    }, [setSearchParams, searchParams]);
}

import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {useGetFittedDistributions} from "src/api/tableData/useGetFittedDistributions.js";
import {sortByValue} from "src/utils/sorting.js";
import {LoadingBlock} from "src/primitives/Loader/LoadingBlock.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {Callout} from "src/primitives/Callout/index.jsx";
import {Button} from "src/primitives/button.jsx";
import {
    getRecommendedTransformation,
    getTransformationReasoning
} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {DistributionDisplayNames} from "src/components/distributions/index.jsx";


export function TransformationExplanation({distributionFit, recommendedTransformation}) {
    const distributionName = distributionFit?.distribution_name;
    const pValue = distributionFit?.p_value;

    return (
        <ul className="list-disc list-inside">
            <li>
                <span>
                    Best distribution fit:
                    <span className="pl-1">
                        {DistributionDisplayNames[distributionName]}
                    </span>
                </span>
            </li>
            <li>
                <span>
                    p-value:
                    <span className="bold monospace pl-1">
                        {formatNumber(pValue, 3)}
                    </span>
                </span>
            </li>
            <li>
                Recommended transformation:
                <span className="bold monospace pl-1">
                    {recommendedTransformation}
                </span>
            </li>
        </ul>
    );
}

TransformationExplanation.propTypes = {
    distributionFit: PropTypes.shape({
        distribution_name: PropTypes.string,
        p_value: PropTypes.number
    }),
    recommendedTransformation: PropTypes.string
};


export function TransformationHint({tableId, column, apply}) {
    const {data, loading} = useGetFittedDistributions(tableId);
    const distributions = useMemo(() => {
        const columnName = column?.name;
        if (!data || !columnName || !data[columnName]) {
            return [];
        }
        const values = data[columnName];
        return sortByValue(values, "p_value", "desc");
    }, [data, column?.name]);
    const bestFit = distributions[0];

    if (!bestFit || bestFit.p_value < 0.001) {
        return (
            <Callout variant="danger">
                <div className="h-16 relative text-sm">
                    No transformation found
                </div>
            </Callout>
        );
    }

    const distributionName = bestFit.distribution_name;
    const recommendedTransformation = getRecommendedTransformation(column, distributionName);

    return (
        <Callout variant="success">
            <div className="min-h-16 relative text-sm">
                {loading && <LoadingBlock/>}
                <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between gap-4">
                        <div>
                            <TransformationExplanation
                                distributionFit={bestFit}
                                recommendedTransformation={recommendedTransformation}
                            />
                        </div>

                        <Button
                            onClick={apply.bind(null, recommendedTransformation)}
                            size="xs"
                            theme="black"
                        >
                            Apply
                        </Button>
                    </div>

                    <div>
                        {getTransformationReasoning(recommendedTransformation, bestFit.distribution_name)}
                    </div>
                </div>
            </div>
        </Callout>
    );
}

TransformationHint.propTypes = {
    tableId: PropTypes.string,
    column: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }),
    apply: PropTypes.func
};

import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiX} from "react-icons/fi";


export function SelectOutlierLimit({
    tableId,
    columnName,
    value,
    onChange,
    shortcuts
}) {
    const lastSelected = useRef(null);
    const {data} = useGetTableStats(tableId);
    const columnStats = data[columnName];

    const handleButtonSelect = useCallback((shortcut) => {
        lastSelected.current = shortcut;
        const val = columnStats[shortcut];
        onChange(val);
    }, [onChange, columnStats]);

    const handleInputChange = useCallback((e) => {
        const val = parseFloat(e.target.value);
        onChange(val);
    }, [onChange]);

    const isActive = (shortcut) => {
        const shortcutValue = columnStats[shortcut];
        return shortcutValue === value && lastSelected.current === shortcut;
    };

    if (!columnStats) {
        return null;
    }

    return (
        <div className="flex items-center gap-2">
            <div className="w-32 mr-2">
                <input
                    className="block w-full border border-black/20 rounded-md text-sm px-2 py-1"
                    type="number"
                    name={columnName}
                    value={value === null ? "" : value}
                    min={columnStats.min}
                    max={columnStats.max}
                    step={0.01}
                    onChange={handleInputChange}
                />
            </div>

            {shortcuts.map((shortcut) => (
                <Button
                    key={shortcut}
                    type="button"
                    theme={isActive(shortcut) ? "black" : "outlineBlack"}
                    size="xs"
                    onClick={handleButtonSelect.bind(null, shortcut)}
                >
                    {shortcut}
                </Button>
            ))}
            <Button
                type="button"
                theme="outlineBlack"
                size="xs"
                onClick={() => onChange(null)}
            >
                <span className="flex items-center ml-[-4px]">
                    <IconWrapper icon={<FiX/>} size="xxs"/>
                    <span>Clear</span>
                </span>
            </Button>
        </div>
    );
}

SelectOutlierLimit.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    shortcuts: PropTypes.arrayOf(PropTypes.oneOf(["p1", "p5", "p25", "p75", "p95", "p99"]))
};

SelectOutlierLimit.defaultProps = {
    shortcuts: ["p1", "p5", "p25", "p75", "p95", "p99"]
};

import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {overrideSystemHandling} from "src/utils/system.js";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {ContentEditableDiv} from "src/primitives/controls/ContentEditable.jsx";
import {generateId} from "src/utils/id.js";
import {useManageDataSidebar} from "src/pages/Home/pages/TablePage/pages/ChatPage/data_sidebar_context.jsx";
import {useActiveDataId} from "src/pages/Home/pages/TablePage/pages/ChatPage/chat_data_context.jsx";


const placeholder = "Ask me anything...";


export function PromptArea({askQuestion, loading}) {
    const [key, setKey] = useState(generateId());
    const [value, setValue] = useState("");
    const {show} = useManageDataSidebar();
    const activeDataId = useActiveDataId();
    const handleUpdate = useCallback((text) => {
        setValue(text);
    }, [setValue]);

    const handleSubmit = (e) => {
        overrideSystemHandling(e);
        if (!value) {
            return;
        }
        askQuestion(value);
        setValue("");
        setKey(generateId());
    };

    useKeyPress(handleSubmit, ["Enter"], {metaKey: true});

    return (
        <div className="max-w-page-sm mx-auto my-8 relative">
            {activeDataId && (
                <div className="absolute left-[100%] h-full flex items-center justify-center pl-8 w-48">
                    <Button
                        theme="outlineBlack"
                        onClick={show}
                    >
                        Show data
                    </Button>
                </div>
            )}

            <div
                className="py-3 px-6 rounded-xl bg-neutral-100 outline outline-1 outline-neutral-200/50"
            >
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="flex gap-4 items-center justify-between">
                        <div className="flex-grow relative">
                            <ContentEditableDiv
                                key={key}
                                onChange={handleUpdate}
                                autoFocus={true}
                                className="rounded-md p-2 text-neutral-800"
                            />
                            {value === "" ? (
                                <div className="absolute inset-0 p-2 text-neutral-500 inert">
                                    {placeholder}
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <Button
                                type="submit"
                                isLoading={loading}
                                isDisabled={!value}
                                theme="secondary"
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

PromptArea.propTypes = {
    askQuestion: PropTypes.func,
    loading: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";
import {ColumnDetails} from "../ColumnDetails/index.jsx";


export function ModelTarget({
    targetColumn,
    targetTransformation,
    setTargetTransformation,
    outliers,
    setOutliers
}) {
    return (
        <ColumnDetails
            column={targetColumn}
            transformation={targetTransformation}
            onChangeTransformation={setTargetTransformation}
            outliers={outliers}
            setOutliers={setOutliers}
        />
    );
}

ModelTarget.propTypes = {
    targetColumn: PropTypes.shape({
        name: PropTypes.string.isRequired,
        variableType: PropTypes.string.isRequired
    }),
    targetTransformation: PropTypes.string,
    setTargetTransformation: PropTypes.func,
    outliers: PropTypes.shape({
        lower: PropTypes.number,
        upper: PropTypes.number
    }),
    setOutliers: PropTypes.func
};

import {config} from "src/config.js";
import {useCallback, useRef, useState} from "react";


export function startStream(sessionId, onNewMessage, onCompleted) {
    const streamUrl = `${config.API_URL}/v1/stream/${sessionId}`;

    return fetch(streamUrl).then((response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        function readStream() {
            reader.read().then(({done, value}) => {
                if (done) {
                    console.log("Stream complete");
                    if (onCompleted) {
                        onCompleted();
                    }
                    return;
                }

                const chunk = decoder.decode(value, {stream: true});
                // console.log("Read chunk:", chunk);
                onNewMessage(chunk);
                // processChunk(chunk);
                readStream();
            }).catch((error) => {
                console.error("Error reading stream:", error);
            });
        }

        readStream();
    }).catch((error) => {
        console.error("Error starting stream:", error);
    });
}

export function useStreaming(sessionId) {
    const [isOpen, setIsOpen] = useState(false);
    const [streamedContent, setStreamedContent] = useState("");

    const initStream = useCallback(() => {
        setIsOpen(true);
        setStreamedContent("");
        const onNewMessage = (chunk) => {
            setStreamedContent((prevContent) => prevContent + chunk);
        };
        const onCompleted = () => {
            setIsOpen(false);
        };
        return startStream(sessionId, onNewMessage, onCompleted);
    }, [sessionId]);

    return [initStream, {content: streamedContent, isOpen}];
}

export function useStreamedMessages(sessionId) {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    // Use a ref to track the latest messages without causing re-renders
    const messagesRef = useRef(messages);

    const updateMessages = useCallback((updateFunc) => {
        const prevMessages = messagesRef.current || [];
        const newMessages = updateFunc(prevMessages);
        messagesRef.current = newMessages;
        setMessages(newMessages);
    }, []);

    const resetMessages = useCallback(() => {
        updateMessages(() => []);
    }, [updateMessages]);

    const initStream = useCallback(() => {
        setIsOpen(true);
        resetMessages();

        const onNewMessage = (chunk) => {
            if (chunk.startsWith("{") && chunk.endsWith("}")) {
                // JSON message
                try {
                    const message = JSON.parse(chunk);
                    if (message.type === "tool_call") {
                        const formattedMessage = {
                            ...message,
                            isLoading: true
                        };
                        updateMessages((prevMessages) => [...prevMessages, formattedMessage]);
                    } else if (message.type === "tool_call_completed") {
                        updateMessages((prevMessages) => {
                            return prevMessages.map((item) => {
                                if (item.id === message.id) {
                                    return {
                                        ...item,
                                        isLoading: false,
                                        time: message.time
                                    };
                                }
                                return item;
                            });
                        });
                    } else if (message.type === "COMPLETED") {
                        console.log("Stream completed");
                    } else {
                        console.warn("Received message of unknown type:", message);
                    }
                } catch (e) {
                    console.error("Error parsing message:", e);
                }
            } else {
                updateMessages((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (!lastMessage || lastMessage.type !== "message") {
                        console.log("new message");
                        const newMessage = {type: "message", content: chunk || ""};
                        return [...prevMessages, newMessage];
                    }

                    // last message is a message, append chunk to its content
                    const updatedMessage = {
                        ...lastMessage,
                        content: lastMessage.content + (chunk || "")
                    };
                    return [
                        ...prevMessages.slice(0, -1),
                        updatedMessage
                    ];
                });
            }
        };

        const onCompleted = () => {
            setIsOpen(false);
        };

        return startStream(sessionId, onNewMessage, onCompleted);
    }, [sessionId, updateMessages, resetMessages]);

    return [initStream, {isOpen, messages}];
}

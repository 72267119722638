import iconNorm from "./assets/norm.svg";
import iconLogNorm from "./assets/lognorm.svg";
import iconUniform from "./assets/uniform.svg";
import iconExpon from "./assets/expon.svg";
import iconGamma from "./assets/gamma.svg";
import iconBeta from "./assets/beta.svg";


export const DISTRIBUTIONS = {
    norm: "norm",
    uniform: "uniform",
    expon: "expon",
    lognorm: "lognorm",
    gamma: "gamma",
    pareto: "pareto"
};


export const DistributionIcons = {
    norm: iconNorm,
    lognorm: iconLogNorm,
    expon: iconExpon,
    uniform: iconUniform,
    gamma: iconGamma,
    pareto: iconBeta // TODO: Update!
};

export const DistributionDisplayNames = {
    lognorm: "Log-normal distribution",
    norm: "Normal distribution",
    expon: "Exponential distribution",
    uniform: "Uniform distribution",
    gamma: "Gamma distribution",
    pareto: "Pareto distribution"
};

import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {Navigate, Route, Routes} from "react-router-dom";
import {PredictionPageFooter} from "./components/PredictionPageFooter/index.jsx";
import {PredictionModelHeader} from "./components/PredictionModelHeader/index.jsx";
import {DeployModel, EvaluateModel, TrainModel} from "./pages";


export function PredictionModelPage({tableId, modelId}) {
    const {model, loading: loadingModel} = useGetPredictionModel(modelId);
    const {table, loading: loadingTable} = useGetTable(tableId);
    const columns = table?.columns || [];

    const target = columns.find((item) => item.isTarget);
    const features = columns.filter((item) => item.isFeature);

    if (loadingTable || loadingModel) {
        return null;
    }

    return (
        <div className="full flex flex-col overflow-hidden">
            <PredictionModelHeader
                tableId={tableId}
                modelId={modelId}
                title={model?.title}
            />
            <div className="flex-grow overflow-auto p-4 pb-12">
                <Routes>
                    <Route
                        path="/train"
                        element={(
                            <TrainModel
                                tableId={tableId}
                                modelId={modelId}
                                defaultTarget={target}
                                defaultFeatures={features}
                                tableColumns={columns}
                            />
                        )}
                    />
                    <Route
                        path="/evaluate"
                        element={(
                            <EvaluateModel
                                tableId={tableId}
                                modelId={modelId}
                            />
                        )}
                    />
                    <Route
                        path="/deploy"
                        element={(
                            <DeployModel
                                tableId={tableId}
                                modelId={modelId}
                            />
                        )}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="train" />}
                    />
                </Routes>
            </div>

            <div className="h-16 shrink-0 grow-0 basis-16">
                <PredictionPageFooter tableId={tableId} modelId={modelId}/>
            </div>
        </div>
    );
}

PredictionModelPage.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired
};

import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {MODEL_RESULT_FIELDS, MODEL_SETTINGS_FIELDS, PREDICTION_MODEL_FIELDS} from "./fragments.js";


export const GET_PREDICTION_MODEL_TRAINING_RESULTS_QUERY = gql`
    ${PREDICTION_MODEL_FIELDS}
    ${MODEL_RESULT_FIELDS}
    ${MODEL_SETTINGS_FIELDS}
    query GetPredictionModelTrainingResults ($modelId: String!) {
        predictionModel (modelId: $modelId) {
            ...PredictionModelFields
            trainingResults {
                id
                createdAt
                modelId
                trainingTimeSeconds
                settings {
                    ... ModelSettingsFields
                }
                result {
                    ...ModelResultFields
                }
            }
        }
    }
`;

export function useGetPredictionModelTrainingResults(modelId) {
    const variables = {modelId};
    const {data, loading, error, refetch} = useQuery(GET_PREDICTION_MODEL_TRAINING_RESULTS_QUERY, {variables});

    const trainingResults = useMemo(() => {
        return data?.predictionModel?.trainingResults || [];
    }, [data]);

    return {
        trainingResults, loading, error, refetch
    };
}

import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


export const GET_CHAT_SESSIONS_QUERY = gql`
    query GetChatSessions($tableId: String!) {
        chatSessions (tableId: $tableId) {
            id
            createdAt
            updatedAt
            title            
            history
        }
    }
`;


export function useGetChatSessions(tableId) {
    const {data, loading, error, refetch} = useQuery(GET_CHAT_SESSIONS_QUERY, {variables: {tableId}});

    const sessions = useMemo(() => {
        return data?.chatSessions || [];
    }, [data]);

    return {
        sessions, loading, error, refetch
    };
}

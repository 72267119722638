import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiChevronRight} from "react-icons/fi";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {useGetPredictionModelTrainingResults} from "src/api/predictionModels/useGetPredictionModelTrainingResults.js";
import {TrainingResultsList} from "./components/TrainingResultsList/index.jsx";


const ButtonLink = ({to, title}) => (
    <NavLink
        to={to}
    >
        {({isActive}) => (
            <Button
                theme={isActive ? "white" : "outline"}
            >
                {title}
            </Button>
        )}
    </NavLink>
);

ButtonLink.propTypes = {
    to: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export function PredictionPageFooter({tableId, modelId}) {
    const getLink = useGetLink();
    const {trainingResults} = useGetPredictionModelTrainingResults(modelId);

    return (
        <div
            className="full flex items-center justify-center text-white relative"
            style={{background: "rgb(51 51 51)"}}
        >
            <div className="flex items-center">
                <ButtonLink
                    to={getLink(LINKS.model.train(tableId, modelId))}
                    title="Train"
                />
                <IconWrapper
                    icon={<FiChevronRight/>}
                />
                <ButtonLink
                    to={getLink(LINKS.model.evaluate(tableId, modelId))}
                    title="Evaluate"
                />
                <IconWrapper
                    icon={<FiChevronRight/>}
                />
                <ButtonLink
                    to={getLink(LINKS.model.deploy(tableId, modelId))}
                    title="Deploy"
                />
            </div>
            <div className="absolute right-8 h-full">
                <TrainingResultsList trainingResults={trainingResults}/>
            </div>
        </div>
    );
}

PredictionPageFooter.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired
};

import {Link} from "react-router-dom";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowLeft} from "react-icons/fi";
import React from "react";
import PropTypes from "prop-types";

export function GoBackLink({link, title}) {
    return (
        <Link
            to={link}
            /* eslint-disable-next-line max-len */
            className="flex items-center rounded-md hover:bg-black/5 pl-1 pr-4 py-0 text-sm text-neutral-600 hover:text-neutral-800 transition-colors"
        >
            <IconWrapper
                icon={<FiArrowLeft/>}
                size="small"
            />
            <span>
                {title}
            </span>
        </Link>
    );
}

GoBackLink.propTypes = {
    link: PropTypes.any,
    title: PropTypes.string
};

GoBackLink.defaultProps = {
    title: "Go back"
};

import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation DeleteChatSession($sessionId: String!) {
        deleteChatSession(sessionId: $sessionId) {
            ok
            errorCode
            sessionId
        }
    }
`;


function removeChatSessionFromCache(cache, response) {
    const {ok, sessionId} = response.data.deleteChatSession;
    if (!ok) {
        return;
    }
    const model = {
        id: sessionId,
        __typename: "ChatSession"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

export function useDeleteChatSession() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((sessionId) => {
        const expectedResponse = {
            ok: true,
            errorCode: null,
            sessionId
        };
        const optimisticResponse = {deleteChatSession: expectedResponse};
        return mutation({
            variables: {sessionId},
            optimisticResponse,
            update: (cache, response) => {
                removeChatSessionFromCache(cache, response);
            }
        }).then((response) => {
            return response.data.deleteChatSession;
        });
    }, [mutation]);

    return [doMutate, {loading, error, result: data?.deleteChatSession}];
}

import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {isColumnContinuous, VariableTypes} from "src/utils/tableData.js";
import {Transformations} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {ColumnComparison} from "./components/ColumnComparison/index.jsx";
import {TransformationHint} from "./components/TransformationHint/index.jsx";
import {ColumnDataPreview} from "./components/DataPreview/index.jsx";
import {SelectTransformation} from "../SelectTransformation/index.jsx";
import {DefineOutliers} from "./components/Outliers/index.jsx";


export function ColumnDetails({
    column,
    transformation,
    onChangeTransformation,
    outliers,
    setOutliers,
    comparisonColumn
}) {
    const tableId = useCurrentTableId();

    if (!column) {
        return null;
    }

    return (
        <div className="border border-gray-300 rounded-md p-4">
            <Title size="xs">
                <span className="uppercase monospace">
                    {column?.name}
                </span>
            </Title>

            <div className="my-4 flex items-center gap-2">
                <Title size="xs">
                    Variable type:
                </Title>
                <Badge>
                    {column?.variableType}
                </Badge>
            </div>

            {column.variableType === VariableTypes.CONTINUOUS && (
                <ColumnDataPreview
                    tableId={tableId}
                    columnName={column.name}
                />
            )}

            <div className="my-4 flex items-center gap-2">
                <Title size="xs">
                    Select transformation:
                </Title>
                <div className="w-64">
                    <SelectTransformation
                        value={transformation}
                        onChange={onChangeTransformation}
                        showLabel={false}
                    />
                </div>
            </div>

            <div
                className={`my-2 ${transformation && "hidden"} transition-all`}
            >
                <TransformationHint
                    tableId={tableId}
                    column={column}
                    apply={onChangeTransformation}
                />
            </div>

            {isColumnContinuous(column) && (
                <div className="my-4">
                    <DefineOutliers
                        tableId={tableId}
                        columnName={column.name}
                        outliers={outliers}
                        setOutliers={setOutliers}
                    />
                </div>
            )}

            {(comparisonColumn && isColumnContinuous(column) && isColumnContinuous(comparisonColumn)) && (
                <div className="mt-6 mb-4">
                    <Title size="xs" style={{margin: 0}}>
                        Comparison with target:
                    </Title>
                    <ColumnComparison
                        tableId={tableId}
                        targetColumn={comparisonColumn.name}
                        featureColumn={column.name}
                    />
                </div>
            )}
        </div>
    );
}

ColumnDetails.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }),
    comparisonColumn: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }),
    transformation: PropTypes.oneOf(Object.values(Transformations)),
    onChangeTransformation: PropTypes.func,
    outliers: PropTypes.shape({
        lower: PropTypes.number,
        upper: PropTypes.number
    }),
    setOutliers: PropTypes.func
};

import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_PREDICTION_MODEL_TRAINING_RESULTS_QUERY} from "./useGetPredictionModelTrainingResults.js";
import {GET_PREDICTION_MODEL_QUERY} from "./useGetPredictionModel.js";
import {MODEL_RESULT_FIELDS, MODEL_SETTINGS_FIELDS} from "./fragments.js";


const MUTATION = gql`
    ${MODEL_RESULT_FIELDS}
    ${MODEL_SETTINGS_FIELDS}
    mutation TrainModel($modelId: String!, $settings: TrainModelInput!) {
        trainModel(modelId: $modelId, settings: $settings) {
            ok
            errorCode
            result {
                id
                createdAt
                modelId
                trainingTimeSeconds
                settings {
                    ... ModelSettingsFields
                }
                result {
                    ...ModelResultFields
                }
            }
        }
    }
`;

export function addTrainingResultToCache(cache, response, modelId) {
    const {ok, result} = response.data.trainModel;
    if (!ok) {
        return;
    }
    // Read the existing items from the cache
    const data = cache.readQuery({query: GET_PREDICTION_MODEL_TRAINING_RESULTS_QUERY, variables: {modelId}});
    const existingTrainingResults = data?.predictionModel?.trainingResults || [];
    const allTrainingResults = [result, ...existingTrainingResults];
    cache.writeQuery({
        query: GET_PREDICTION_MODEL_TRAINING_RESULTS_QUERY,
        variables: {modelId},
        data: {
            predictionModel: Object.assign({}, data?.predictionModel, {trainingResults: allTrainingResults})
        }
    });
}

export function useTrainModel(modelId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((settings) => {
        const refetchQueries = [{query: GET_PREDICTION_MODEL_QUERY, variables: {modelId}}];
        return mutation({
            variables: {modelId, settings},
            refetchQueries,
            update(cache, response) {
                addTrainingResultToCache(cache, response, modelId);
            }
        }).then((response) => {
            return response.data.trainModel;
        });
    }, [mutation, modelId]);

    return [doMutate, {loading, error, result: data?.trainModel?.result}];
}

import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {ColumnDetails} from "../ColumnDetails/index.jsx";


const getOutliersPlaceholder = () => ({
    lower: null,
    upper: null
});


export function ModelFeatures({target, features, setTransformation, setOutliers}) {
    const handleOutliersChange = useCallback((columnName, updateFunc) => {
        const column = features.find((item) => item.name === columnName);
        const newOutliers = updateFunc(column.outliers || getOutliersPlaceholder());
        setOutliers(columnName, newOutliers);
    }, [setOutliers, features]);

    return (
        <div className="flex flex-col gap-4">
            {features.map((column) => (
                <ColumnDetails
                    key={`feature-${column.name}`}
                    column={column}
                    transformation={column.transformation}
                    onChangeTransformation={(val) => setTransformation(column.name, val)}
                    outliers={column.outliers || getOutliersPlaceholder()}
                    setOutliers={handleOutliersChange.bind(null, column.name)}
                    comparisonColumn={target}
                />
            ))}
        </div>
    );
}


ModelFeatures.propTypes = {
    features: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.object,
    setTransformation: PropTypes.func,
    setOutliers: PropTypes.func
};

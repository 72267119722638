import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {useTrainModel} from "src/api/predictionModels/useTrainModel.js";
import {modelOptions, RegressionModel} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {Button, IconButton} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";
import {FiPlus, FiTarget} from "react-icons/fi";
import {SelectColumn} from "src/components/controls/SelectColumn/index.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {ModelTarget} from "./components/ModelTarget/index.jsx";
import {ModelFeatures} from "./components/ModelFeatures/index.jsx";
import {useSelectedFeatures} from "./useSelectedFeatures.jsx";
import {ModelResultTable} from "../EvaluateModel/components/ModelResultTable/index.jsx";
import {PageTitle} from "../PageTitle.jsx";


export function TrainModel({tableId, modelId, defaultTarget, defaultFeatures, tableColumns}) {
    const columnsRef = useRef();
    columnsRef.current = arrayToMap(tableColumns, "name");
    const {model: storedModel} = useGetPredictionModel(modelId);
    const [selectedModel, setSelectedModel] = useState(RegressionModel.LINEAR);
    const [selectedTarget, setSelectedTarget] = useState(defaultTarget);
    const [targetTransformation, setTargetTransformation] = useState(null);
    const [targetOutliers, setTargetOutliers] = useState({lower: null, upper: null});
    const [
        selectedFeatures,
        {setFeatures, toggleFeature, setTransformation, setOutliers}] = useSelectedFeatures(defaultFeatures);
    const [doTrain, {loading}] = useTrainModel(modelId);

    const getModelSettings = () => {
        return {
            model: selectedModel,
            targetColumn: selectedTarget?.name,
            targetTransformation,
            targetOutliers: {
                lower: targetOutliers?.lower,
                upper: targetOutliers?.upper
            },
            features: selectedFeatures.map((item) => {
                return {
                    name: item.name,
                    transformation: item.transformation,
                    outliers: {
                        lower: item?.outliers?.lower,
                        upper: item?.outliers?.upper
                    }
                };
            })
        };
    };

    const handleTrainModel = () => {
        if (!selectedTarget || !selectedModel || !selectedFeatures) {
            return;
        }
        const settings = getModelSettings();
        doTrain(settings);
    };

    const handleSelectTarget = (column) => {
        setSelectedTarget(column);
    };

    const isColumnSelectedFeature = (columnName) => {
        return selectedFeatures.find((item) => item.name === columnName);
    };

    useEffect(() => {
        // received model from server
        if (!storedModel?.settings) {
            return;
        }
        const cm = columnsRef.current;
        if (!cm) {
            return;
        }

        console.log("Load settings from stored model");
        const targetColumn = cm[storedModel.settings.target];
        setSelectedModel(storedModel.settings.model);
        setSelectedTarget(targetColumn);
        setTargetTransformation(storedModel.settings.targetTransformation);
        const tOutliers = {
            lower: storedModel.settings.targetOutliers?.lower,
            upper: storedModel.settings.targetOutliers?.upper
        };
        setTargetOutliers(tOutliers);
        const features = storedModel.settings.features.map(({name, transformation, outliers}) => {
            return {
                ...cm[name],
                transformation,
                outliers: {
                    lower: outliers?.lower,
                    upper: outliers?.upper
                }
            };
        });
        setFeatures(features);
    }, [storedModel, setFeatures]);

    // const sortedFeatures = sortFeaturesByVariables(selectedFeatures, tableColumns);

    return (
        <div>
            <PageTitle>
                Train model
            </PageTitle>

            <div className="mb-6 max-w-160">
                <Title size="small">
                    Select regression model
                </Title>
                <div className="max-w-80">
                    <SelectInput
                        onChange={(option) => setSelectedModel(option?.value)}
                        options={modelOptions}
                        selectedOption={modelOptions.find((item) => item.value === selectedModel)}
                    />
                </div>
            </div>

            <div className="mb-8 min-h-24 max-w-160">
                <div className="flex items-center justify-between mb-2 border-b border-black/10">
                    <Title size="small">
                        Target column
                    </Title>
                    <div className="text-sm">
                        <SelectColumn
                            tableId={tableId}
                            onSelect={handleSelectTarget}
                            selected={selectedTarget?.name}
                        >
                            <IconButton
                                icon={<FiTarget/>}
                                text="Select target"
                            />
                        </SelectColumn>
                    </div>
                </div>
                <ModelTarget
                    targetColumn={selectedTarget}
                    targetTransformation={targetTransformation}
                    setTargetTransformation={setTargetTransformation}
                    outliers={targetOutliers}
                    setOutliers={setTargetOutliers}
                />
            </div>

            <div className="mb-8 min-h-24 max-w-160">
                <div className="flex items-center justify-between mb-2 border-b border-black/10">
                    <Title size="small">
                        Features
                    </Title>
                    <div className="text-sm">
                        <SelectColumn
                            tableId={tableId}
                            onSelect={toggleFeature}
                            isSelected={isColumnSelectedFeature}
                        >
                            <IconButton
                                icon={<FiPlus/>}
                                text="Select features"
                            />
                        </SelectColumn>
                    </div>
                </div>
                <ModelFeatures
                    tableId={tableId}
                    features={selectedFeatures}
                    target={selectedTarget}
                    setTransformation={setTransformation}
                    setOutliers={setOutliers}
                />
            </div>

            <div className="mb-4">
                <div className="flex flex-col gap-4 max-w-80">
                    <div />
                    <div>
                        <Button
                            onClick={handleTrainModel}
                            isLoading={loading}
                            isDisabled={!selectedTarget || !selectedModel}
                        >
                            Train model
                        </Button>
                    </div>
                </div>
            </div>

            {storedModel?.result && (
                <div className="mb-4">
                    <Title size="small">
                        Result:
                    </Title>
                    <ModelResultTable result={storedModel.result} />
                </div>
            )}
        </div>
    );
}

TrainModel.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired,
    defaultTarget: PropTypes.object,
    defaultFeatures: PropTypes.arrayOf(PropTypes.object),
    tableColumns: PropTypes.arrayOf(PropTypes.object)
};

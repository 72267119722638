import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {
    transformationOptions,
    Transformations
} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";


export function SelectTransformation({
    value,
    onChange,
    showLabel
}) {
    const handleChange = useCallback((option) => {
        onChange(option?.value);
    }, [onChange]);
    const selectedOption = transformationOptions.find((item) => item.value === value);

    return (
        <div className="max-w-80">
            <SelectInput
                label={showLabel ? "Target transformation" : null}
                options={transformationOptions}
                onChange={handleChange}
                selectedOption={selectedOption}
            />
        </div>
    );
}

SelectTransformation.propTypes = {
    value: PropTypes.oneOf(Object.values(Transformations)),
    onChange: PropTypes.func,
    showLabel: PropTypes.bool
};

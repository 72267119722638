import React from "react";
import PropTypes from "prop-types";
import {sortByDate} from "src/utils/sorting.js";
import {Title} from "src/primitives/title.jsx";
import {formatDate} from "src/utils/formatting.js";
import {Link} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {LINKS} from "src/links.js";
import {useDeleteChatSession} from "src/api/chat/useDeleteChatSession.js";
import {Button} from "src/primitives/button.jsx";
import {overrideSystemHandling} from "src/utils/system.js";


function ChatSession({session}) {
    const tableId = useCurrentTableId();
    const getLink = useGetLink();
    const [deleteSession] = useDeleteChatSession();
    const [isPending, setIsPending] = React.useState(false);

    const handleDeleteClick = (e) => {
        overrideSystemHandling(e);
        setIsPending(true);
        deleteSession(session.id).then(({ok, errorCode}) => {
            if (!ok) {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.error(error);
            setIsPending(false);
        });
    };

    if (isPending) {
        return null;
    }

    return (
        <Link
            to={getLink(LINKS.tableChatSession(tableId, session.id))}
            className="block border border-neutral-200 rounded-md px-3 py-2 hover:bg-black-3 transition-all"
        >
            <div className={isPending ? "opacity-50" : ""}>
                <div className="w-full flex flex-col gap-2">
                    <div className="flex-grow">
                        <Title size="xs">
                            {session.title}
                        </Title>
                    </div>
                    <div className="text-neutral-500 text-sm">
                        Created {formatDate(session.createdAt)}
                    </div>
                    <div>
                        <Button
                            onClick={handleDeleteClick}
                            size="xs"
                            theme="danger"
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </Link>
    );
}

ChatSession.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
    }).isRequired
};

export function ExistingSessions({sessions, loading}) {
    if (loading || sessions.length === 0) {
        return null;
    }

    const sortedSessions = sortByDate(sessions, "createdAt", "desc");

    return (
        <div className="w-full flex flex-col gap-4">
            <div>
                <Title size="xs">
                    Previous sessions
                </Title>
            </div>
            {sortedSessions.map((session) => (
                <ChatSession key={session.id} session={session}/>
            ))}
        </div>
    );
}

ExistingSessions.propTypes = {
    loading: PropTypes.bool.isRequired,
    sessions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
    }))
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";


export function TabsNavigation({tabs}) {
    const getLink = useGetLink();

    return (
        <Wrapper>
            {tabs.map((tab) => (
                <TabItem key={`tab-${tab.link}`}>
                    <NavLink
                        className="transition-all"
                        to={getLink(tab.link)}
                        key={`tab-${tab.link}`}
                        end={!tab.inclusive}
                    >
                        <span>
                            {tab.title}
                        </span>
                    </NavLink>
                </TabItem>
            ))}
        </Wrapper>
    );
}

TabsNavigation.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.node,
        content: PropTypes.element,
        inclusive: PropTypes.bool
    }))
};

const Wrapper = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  & a {
    font-size: 14px;
    font-weight: bold;
    display: block;
    padding: 9px 16px 8px 16px;
    margin-bottom: -1px;
  }

  & a:hover {
    color: #000;
    background-color: rgba(0, 0, 0, 0.025);
    border-bottom: 1px solid rgb(201 201 201);    
  }

  & .active, & .active:hover {
    // color: #4d7c0f; // lime-700
    color: #65a30d; // lime-600    
    border-bottom: 1px solid #65a30d; // lime-600
  }
`;

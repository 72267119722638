import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {PREDICTION_MODEL_FIELDS} from "./fragments.js";


export const GET_PREDICTION_MODELS_QUERY = gql`
    ${PREDICTION_MODEL_FIELDS}
    query GetPredictionModels ($tableId: String!) {
        predictionModels (tableId: $tableId) {
            ...PredictionModelFields
        }
    }
`;

export function useGetPredictionModels(tableId) {
    const variables = {tableId};
    const {data, loading, error, refetch} = useQuery(GET_PREDICTION_MODELS_QUERY, {variables});

    const models = useMemo(() => {
        return data?.predictionModels || [];
    }, [data]);

    return {
        models, loading, error, refetch
    };
}

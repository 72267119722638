import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {formatNumber, formatPrettyString} from "src/utils/formatting.js";

function inferSchema(data) {
    function getType(value) {
        if (typeof value === "string") {
            // Check if the string can be parsed as a float
            return Number.isNaN(parseFloat(value)) ? "string" : "float";
        }
        if (typeof value === "number") {
            return value % 1 === 0 ? "integer" : "float";
        }
        return "string"; // Default to string if no match
    }

    // Assume all objects in the array have the same structure
    return Object.keys(data[0]).map((column) => {
        const dataType = getType(data[0][column]);
        return {
            column,
            dataType
        };
    });
}

export function ChatDataTable({data, columnOrder}) {
    if (!data) {
        return null;
    }

    const schema = inferSchema(data);
    const columns = schema.map(({column, dataType}) => ({
        key: column,
        header: formatPrettyString(column),
        render: (value) => {
            if (dataType === "integer") {
                return formatNumber(parseInt(value, 10), 0);
            }
            if (dataType === "float") {
                return formatNumber(parseFloat(value), 2);
            }
            return value;
        }
    }));
    columns.sort((a, b) => columnOrder.indexOf(a.key) - columnOrder.indexOf(b.key));

    return (
        <div className="p-2">
            <SimpleTable
                data={data}
                columns={columns}
                maxRows={10}
            />
        </div>
    );
}

ChatDataTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columnOrder: PropTypes.arrayOf(PropTypes.string)
};

import React from "react";
import PropTypes from "prop-types";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {PredictionModelsPage} from "./pages/PredictionModelsPage/index.jsx";
import {PredictionModelPage} from "./pages/PredictionModelPage/index.jsx";


function RenderPredictionModelPage() {
    const getLink = useGetLink();
    const {modelId, tableId} = useParams();
    const {model, loading} = useGetPredictionModel(modelId);

    if (loading) {
        return null;
    }

    if (!model) {
        return <Navigate to={getLink(LINKS.tablePredict(tableId))}/>;
    }

    return (
        <PredictionModelPage modelId={modelId} tableId={tableId}/>
    );
}


export function TablePredictPage({tableId}) {
    return (
        <Routes>
            <Route
                path=""
                element={<PredictionModelsPage tableId={tableId}/>}
            />
            <Route
                path="/:modelId/*"
                element={<RenderPredictionModelPage/>}
            />
            <Route
                path="*"
                element={<Navigate to="" />}
            />
        </Routes>
    );
}

TablePredictPage.propTypes = {
    tableId: PropTypes.string.isRequired
};

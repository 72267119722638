import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useActiveDataId} from "src/pages/Home/pages/TablePage/pages/ChatPage/chat_data_context.jsx";
import {
    useDataSidebarState,
    useManageDataSidebar
} from "src/pages/Home/pages/TablePage/pages/ChatPage/data_sidebar_context.jsx";
import {FiX} from "react-icons/fi";
import {IconButton} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";
import {DataItem} from "./components/DataItem/index.jsx";


export function ChatSessionData({data}) {
    const activeDataId = useActiveDataId();
    const isVisible = useDataSidebarState();
    const {show, close} = useManageDataSidebar();
    const [latestDataId, setLatestDataId] = useState(null);

    useEffect(() => {
        if (!data) {
            return;
        }
        const toolCallIds = data.filter((item) => !!item.toolCallId).map((item) => item.toolCallId);
        const latestId = toolCallIds[toolCallIds.length - 1];
        if (latestId !== latestDataId) {
            setLatestDataId(latestId);
        }
    }, [data, latestDataId]);

    useEffect(() => {
        if (latestDataId) {
            show();
        }
    }, [latestDataId, show]);

    useEffect(() => {
        if (activeDataId) {
            show();
        }
    }, [activeDataId, show]);

    if (!data) {
        return null;
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div
            /* eslint-disable-next-line max-len */
            className="h-full flex flex-col rounded-md bg-black/85 border border-black/95 text-white"
        >
            <div className="px-6 py-2 mb-2 border-b border-white flex items-center justify-between">
                <Title size="small">
                    Fetched data
                </Title>
                <IconButton
                    icon={<FiX/>}
                    onClick={close}
                    theme="white"
                    size="xs"
                    text="Close"
                    style={{background: "green"}}
                />
            </div>
            <div
                className="flex-grow my-2 pb-4 pl-6 pr-4 overflow-y-scroll custom-scrollbar flex flex-col gap-6"
            >
                {data.map((item, index) => (
                    <DataItem
                        key={item.id}
                        itemIndex={1 + index}
                        item={item}
                        isActive={item.toolCallId === activeDataId}
                    />
                ))}
            </div>
        </div>
    );
}

ChatSessionData.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        sessionId: PropTypes.string,
        toolCallId: PropTypes.string,
        name: PropTypes.string,
        query: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.object)
    }))
};

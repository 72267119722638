import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";


export const ChatDataContext = createContext(null);


export const SetChatDataContext = createContext(null);


export function ChatDataContextProvider({children}) {
    const [activeDataId, setActiveDataId] = useState(null);

    return (
        <SetChatDataContext.Provider value={setActiveDataId}>
            <ChatDataContext.Provider value={activeDataId}>
                {children}
            </ChatDataContext.Provider>
        </SetChatDataContext.Provider>
    );
}

ChatDataContextProvider.propTypes = {
    children: PropTypes.element
};

export function useActiveDataId() {
    return useContext(ChatDataContext);
}

export function useSetActiveDataId() {
    return useContext(SetChatDataContext);
}

import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {getAxisLabelStyle, getDefaultTextStyle} from "src/charts/options.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {abbreviateNumber} from "src/utils/formatting.js";


function getOption(values, options) {
    const {showLabel, showTooltip, showY, showXLabel, xFormatter, color} = options;

    return {
        textStyle: getDefaultTextStyle(),
        grid: {
            top: "5%",
            left: showY ? "20%" : "5%",
            width: showY ? "75%" : "90%",
            bottom: showXLabel ? "20%" : "5%"
        },
        dataset: [{
            source: values
        }, {
            transform: {
                type: "ecStat:histogram",
                config: {
                    // method: "scott",
                    method: "sturges"
                }
            }
        }],
        tooltip: {
            show: showTooltip
        },
        xAxis: [{
            scale: true,
            gridIndex: 0,
            axisLabel: {
                formatter: (value) => {
                    return xFormatter ? xFormatter(value) : abbreviateNumber(value);
                },
                ...getAxisLabelStyle()
            }
        }],
        yAxis: [{
            gridIndex: 0,
            show: showY,
            axisLabel: {
                ...getAxisLabelStyle()
            }
        }],
        series: [{
            name: "histogram",
            type: "bar",
            barWidth: "80%",
            label: {
                show: showLabel,
                position: "top"
            },
            encode: {x: 0, y: 1},
            datasetIndex: 1,
            itemStyle: {
                color
            }
        }]
    };
}


export function Histogram({
    values, min, max, height, showTooltip, showLabel, showY, showXLabel, xFormatter, color
}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=bar-histogram
     * https://github.com/ecomfe/echarts-stat#histogram
     */


    // eslint-disable-next-line no-param-reassign
    values = values.filter((v) => Number.isFinite(v));

    if (min) {
        // eslint-disable-next-line no-param-reassign
        values = values.filter((v) => v >= min);
    }
    if (max) {
        // eslint-disable-next-line no-param-reassign
        values = values.filter((v) => v <= max);
    }

    if (!values) {
        return null;
    }
    const formattedValues = values.map((v) => [v]);
    const option = getOption(
        formattedValues,
        {showLabel, showTooltip, showY, showXLabel, xFormatter, color}
    );

    return (
        <ChartWrapper option={option} height={height}/>
    );
}

Histogram.propTypes = {
    values: PropTypes.arrayOf(PropTypes.number),
    min: PropTypes.number,
    max: PropTypes.number,
    height: PropTypes.number,
    showTooltip: PropTypes.bool,
    showLabel: PropTypes.bool,
    showY: PropTypes.bool,
    showXLabel: PropTypes.bool,
    xFormatter: PropTypes.func,
    color: PropTypes.string
};

Histogram.defaultProps = {
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    showTooltip: true,
    showLabel: false,
    showY: true,
    showXLabel: true,
    // https://echarts.apache.org/en/option.html#xAxis.axisLabel.formatter
    xFormatter: null,
    color: CHART_COLORS.lime
};

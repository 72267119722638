import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {CORE_FOLDER_FIELDS, CORE_TABLE_FIELDS} from "src/api/fragments.js";
import {isLocal} from "src/config.js";

export const GET_WORKSPACE_RESOURCES_QUERY = gql`
${CORE_TABLE_FIELDS}
${CORE_FOLDER_FIELDS}
query GetWorkspaceResources ($id: String!) {
  workspace (workspaceId: $id) {
    id
    tables {
      ... CoreTableFields
    }
    folders {
      ... CoreFolderFields
    }
  }
}
`;

export function useGetWorkspaceResources(workspaceId, refresh = false) {
    const options = {
        variables: {id: workspaceId},
        skip: !workspaceId,
        pollInterval: (refresh && !isLocal()) ? 30 * 1000 : null
    };
    const {data, loading, error, refetch} = useQuery(GET_WORKSPACE_RESOURCES_QUERY, options);

    const tables = useMemo(() => {
        return data?.workspace?.tables || [];
    }, [data]);
    const folders = useMemo(() => {
        return data?.workspace?.folders || [];
    }, [data]);

    return {
        tables, folders, loading, error, refetch
    };
}

import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation Predict($modelId: String!, $data: JSON!) {
        predict(modelId: $modelId, data: $data) {
            ok
            errorCode
            result
        }
    }
`;


export function usePredict(modelId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((inputData) => {
        return mutation({
            variables: {modelId, data: inputData}
        }).then((response) => {
            return response.data.predict;
        });
    }, [mutation, modelId]);

    return [doMutate, {loading, error, result: data?.predict?.result}];
}

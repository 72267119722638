import React from "react";
import PropTypes from "prop-types";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";
import {formatPercent, formatTimestamp} from "src/utils/formatting.js";


function TrainingResultItem({trainingResult}) {
    const {settings, result} = trainingResult;

    return (
        <div
            className="border-b border-neutral-300 pb-2 mb-2 last:border-0 last:mb-0 last:pb-0"
        >
            <div
                className="text-xs flex flex-col gap-1"
            >
                <div>
                    {formatTimestamp(trainingResult.createdAt)}
                </div>
                <div>
                    Model: {settings?.model}, {settings?.features.length} features.
                </div>
                <div />
                <div>
                    R2: {formatPercent(result?.r2)}
                </div>
            </div>
        </div>
    );
}

TrainingResultItem.propTypes = {
    trainingResult: PropTypes.shape({
        createdAt: PropTypes.string,
        settings: PropTypes.shape({
            model: PropTypes.string,
            features: PropTypes.array
        }),
        result: PropTypes.shape({
            r2: PropTypes.number
        })
    })
};


export function TrainingResultsList({trainingResults}) {
    // eslint-disable-next-line no-unused-vars
    const [value, setTrue, setFalse, toggle] = useBooleanState();

    if (!trainingResults || trainingResults.length === 0) {
        return null;
    }

    return (
        <div className="h-full relative flex items-center justify-center">
            <div>
                <button
                    type="button"
                    onClick={toggle}
                    className="text-sm border border-white/80 rounded-md px-3 py-2 hover:bg-white/5 opacity-90"
                >
                    {trainingResults.length} training runs
                </button>
            </div>
            {value && (
                <div
                    className="absolute w-80 right-0 overflow-y-auto border border-neutral-400 rounded-md p-4 shadow-sm"
                    style={{
                        bottom: 0,
                        background: "white",
                        color: "black",
                        transform: "translateY(-72px)",
                        maxHeight: 720,
                        minHeight: 200
                    }}
                >
                    <div className="flex flex-col gap-2">
                        {trainingResults.map((item) => (
                            <TrainingResultItem
                                key={item.id}
                                trainingResult={item}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

TrainingResultsList.propTypes = {
    trainingResults: PropTypes.arrayOf(PropTypes.object)
};

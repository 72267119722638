import React from "react";
import PropTypes from "prop-types";
import {Callout} from "src/primitives/Callout/index.jsx";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {ModelPreview} from "./components/ModelPreview/index.jsx";
import {PageTitle} from "../PageTitle.jsx";


export function DeployModel({tableId, modelId}) {
    const {model, loading} = useGetPredictionModel(modelId);

    if (loading) {
        return null;
    }

    const hasResult = model?.settings && model?.result;

    return (
        <div className="mb-12">
            <PageTitle>
                Deploy model
            </PageTitle>
            <div className="max-w-128">
                {hasResult && (
                    <Callout variant="success">
                        The result from the last training is automatically deployed.
                    </Callout>
                )}
                {!hasResult && (
                    <Callout variant="danger">
                        No model have been trained yet
                    </Callout>
                )}
            </div>

            {hasResult && (
                <ModelPreview
                    modelId={modelId}
                    tableId={tableId}
                />
            )}
        </div>
    );
}

DeployModel.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired
};

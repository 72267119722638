import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_PREDICTION_MODELS_QUERY} from "./useGetPredictionModels.js";
import {PREDICTION_MODEL_FIELDS} from "./fragments.js";


const MUTATION = gql`
    ${PREDICTION_MODEL_FIELDS}
    mutation CreatePredictionModel($tableId: String!) {
        createPredictionModel(tableId: $tableId) {
            ok
            errorCode
            model {
                ... PredictionModelFields
            }
        }
    }
`;


export function addModelToCache(cache, tableId, response) {
    const {ok, model} = response.data.createPredictionModel;
    if (!ok) {
        return;
    }
    // Read the existing items from the cache
    const data = cache.readQuery({query: GET_PREDICTION_MODELS_QUERY, variables: {tableId}});
    const existingItems = data?.predictionModels || [];
    const updatedItems = [...existingItems, model];
    cache.writeQuery({
        query: GET_PREDICTION_MODELS_QUERY,
        variables: {tableId},
        data: {
            predictionModels: updatedItems
        }
    });
}


export function useCreatePredictionModel(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);
    const doMutate = useCallback(() => {
        // const refetchQueries = [{query: GET_PREDICTION_MODELS_QUERY, variables: {tableId}}];

        return mutation({
            variables: {tableId},
            update: (cache, response) => {
                addModelToCache(cache, tableId, response);
            }
        }).then((response) => {
            return response.data.createPredictionModel;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.createPredictionModel}];
}

import React, {useState} from "react";
import PropTypes from "prop-types";
import {FiCode, FiTable} from "react-icons/fi";
import {Title} from "src/primitives/title.jsx";
import {CopyCode} from "src/primitives/Terminal/CopyCode.jsx";
import {extractColumnOrder, formatSQL} from "src/primitives/Terminal/formatting.js";
import {IconButton} from "src/primitives/button.jsx";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {ChatDataTable} from "./ChatDataTable/index.jsx";


const ViewTabs = {
    DATA: "data",
    SQL: "sql"
};

function replaceTableId(query, tableId, tableName) {
    if (!query) {
        return "";
    }
    if (!tableId || !tableName) {
        return query;
    }
    const replaceValue = `"${tableName}"`;
    return query.replaceAll(tableId, replaceValue).replaceAll(`"${tableId}"`, replaceValue);
}

export function DataItem({itemIndex, item}) {
    const tableId = useCurrentTableId();
    const {table} = useGetTable(tableId);
    const sqlQuery = replaceTableId(item?.query, tableId, table?.name);
    const [activeTab, setActiveTab] = useState(ViewTabs.DATA);

    const getTheme = (isActive) => {
        if (isActive) {
            return "outline";
        }
        return "black";
    };

    return (
        <div className="flex flex-col">
            <div className="mb-2">
                <Title size="xs">
                    {itemIndex ? <span className="mr-1">{itemIndex})</span> : null}
                    {item.name}
                </Title>
            </div>

            <div className="flex justify-end">
                <div className="flex items-center gap-2">
                    <IconButton
                        size="xs"
                        icon={<FiTable/>}
                        onClick={() => setActiveTab(ViewTabs.DATA)}
                        text="Data"
                        theme={getTheme(activeTab === ViewTabs.DATA)}
                    />
                    <IconButton
                        size="xs"
                        icon={<FiCode/>}
                        onClick={() => setActiveTab(ViewTabs.SQL)}
                        text="SQL"
                        theme={getTheme(activeTab === ViewTabs.SQL)}
                    />
                </div>
            </div>

            <div className="bg-neutral-900 my-2 rounded-md fade-in min-h-12">
                {activeTab === ViewTabs.DATA && (
                    <ChatDataTable data={item?.data} columnOrder={extractColumnOrder(sqlQuery)}/>
                )}
                {activeTab === ViewTabs.SQL && (
                    <CopyCode
                        code={formatSQL(sqlQuery)}
                        language="sql"
                    />
                )}
            </div>
        </div>
    );
}


DataItem.propTypes = {
    itemIndex: PropTypes.number,
    item: PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        sessionId: PropTypes.string,
        toolCallId: PropTypes.string,
        name: PropTypes.string,
        query: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.object)
    })
};

import {isNullOrUndefined} from "src/utils/misc.js";


export function sortByDate(array, dateKey = "date", order = "asc") {
    const copy = array.slice(0);

    copy.sort((a, b) => {
        if (order === "desc") {
            return new Date(b[dateKey]) - new Date(a[dateKey]);
        }
        return new Date(a[dateKey]) - new Date(b[dateKey]);
    });
    return copy;
}


export function sortByValue(array, valueKey = "value", order = "asc") {
    const copy = array.slice(0);

    const compare = (x, y) => order === "asc" ? x - y : y - x;

    copy.sort((a, b) => {
        return compare(a[valueKey], b[valueKey]);
    });
    return copy;
}


export function sortByString(array, key = "name") {
    const copy = array.slice(0);
    const locale = "sv-SE";

    copy.sort((a, b) => {
        if (isNullOrUndefined(a)) {
            return -1;
        }
        if (isNullOrUndefined(b)) {
            return 1;
        }

        const aValue = typeof a === "string" ? a : a[key];
        const bValue = typeof b === "string" ? b : b[key];

        if (isNullOrUndefined(aValue)) {
            return -1;
        }
        if (isNullOrUndefined(bValue)) {
            return 1;
        }
        return aValue.localeCompare(bValue, locale);
    });
    return copy;
}


export function reverseArray(arr) {
    const copy = arr.slice();
    copy.reverse();
    return copy;
}

export function smartSorting(data, key, order = "asc") {
    // by ChatGPT!
    return data.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];

        // Handle null/undefined values
        const isANull = valA === null || valA === undefined;
        const isBNull = valB === null || valB === undefined;

        if (isANull && isBNull) return 0;
        if (isANull) return 1; // Nulls should be last
        if (isBNull) return -1; // Nulls should be last

        // Handle numbers
        if (typeof valA === "number" && typeof valB === "number") {
            return order === "asc" ? valA - valB : valB - valA;
        }

        // Handle dates
        if (valA instanceof Date && valB instanceof Date) {
            return order === "asc" ? valA - valB : valB - valA;
        }

        // Handle strings (localeCompare)
        if (typeof valA === "string" && typeof valB === "string") {
            return order === "asc" ? valA.localeCompare(valB) : valB.localeCompare(valA);
        }

        // Fallback to string comparison for other types
        const strA = String(valA);
        const strB = String(valB);
        return order === "asc" ? strA.localeCompare(strB) : strB.localeCompare(strA);
    });
}

import {useCallback} from "react";
import {gql, useApolloClient, useMutation} from "@apollo/client";
import {GET_CHAT_SESSION_QUERY} from "src/api/chat/useGetChatSession.js";


const MUTATION = gql`
    mutation AskQuestion($sessionId: String!, $question: String!) {
        askQuestion(sessionId: $sessionId, question: $question) {
            ok
            errorCode
            result {
                sessionId
                question
                response
            }
        }
    }
`;

export function useAddQuestionToChatHistory(sessionId) {
    const client = useApolloClient();

    const addUserQuestionToChatHistory = useCallback((question) => {
        // Read the existing items from the cache
        const data = client.cache.readQuery({query: GET_CHAT_SESSION_QUERY, variables: {sessionId}});
        const existingItems = data?.chatSession?.history || [];
        const newItem = {
            role: "user",
            content: question
        };
        const updatedHistory = [...existingItems, newItem];
        client.cache.writeQuery({
            query: GET_CHAT_SESSION_QUERY,
            variables: {sessionId},
            data: {
                chatSession: {
                    ...data.chatSession,
                    history: updatedHistory
                }
            }
        });
    }, [client, sessionId]);

    return [addUserQuestionToChatHistory];
}

export function useAskQuestion(sessionId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);
    const [addUserQuestionToChatHistory] = useAddQuestionToChatHistory(sessionId);

    const doMutate = useCallback((question) => {
        addUserQuestionToChatHistory(question);
        const refetchQueries = [{query: GET_CHAT_SESSION_QUERY, variables: {sessionId}}];
        return mutation({
            variables: {sessionId, question},
            refetchQueries,
            awaitRefetchQueries: true
        }).then((response) => {
            return response.data.askQuestion;
        });
    }, [mutation, sessionId, addUserQuestionToChatHistory]);

    return [doMutate, {loading, error, result: data?.askQuestion?.result}];
}

import React from "react";
import PropTypes from "prop-types";
import {PageContent} from "src/primitives/layout/index.jsx";
import {PredictionModels} from "./components/PredictionModels/index.jsx";


export function PredictionModelsPage({tableId}) {
    return (
        <PageContent title="Prediction models" size="xl">
            <PredictionModels tableId={tableId}/>
        </PageContent>
    );
}


PredictionModelsPage.propTypes = {
    tableId: PropTypes.string.isRequired
};

import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {useDataTransformationSuggestions} from "src/api/chat/useDataTransformationSuggestions.js";


export function Suggestions({tableId}) {
    const [doMutate, {loading, error, result}] = useDataTransformationSuggestions(tableId);

    if (error) {
        console.log(error);
    }
    if (result) {
        console.log(result);
    }
    return (
        <div>
            <Button
                loading={loading}
                onClick={() => doMutate()}
            >
                Get suggestions
            </Button>
        </div>
    );
}

Suggestions.propTypes = {
    tableId: PropTypes.string.isRequired
};

import React from "react";
import PropTypes from "prop-types";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {Title} from "src/primitives/title.jsx";
import {PageTitle} from "../PageTitle.jsx";
import {ModelResiduals} from "./components/ModelResiduals/index.jsx";
import {ModelResultTable} from "./components/ModelResultTable/index.jsx";


export function EvaluateModel({modelId}) {
    const {model, loading: loadingModel} = useGetPredictionModel(modelId);

    if (loadingModel) {
        return null;
    }

    if (!model) {
        return (
            <div>
                No model found
            </div>
        );
    }

    return (
        <div>
            <PageTitle>
                Model evaluation
            </PageTitle>

            <Title size="small">
                Result overview
            </Title>
            <ModelResultTable result={model?.result}/>
            <ModelResiduals residuals={model?.residuals}/>
        </div>
    );
}

EvaluateModel.propTypes = {
    modelId: PropTypes.string.isRequired
};

import React from "react";
import PropTypes from "prop-types";
import {useGetColumnDistributions} from "src/api/tableData/useGetColumnDistributions.js";
import {
    formatDistributionToLine,
    maxValueFromDistribution,
    minValueFromDistribution
} from "src/components/TableGrid/Header/ColumnFilter/components/utils.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {SmoothLineChart} from "src/charts/SmoothLineChart.jsx";
import {LoadingBlock} from "src/primitives/Loader/LoadingBlock.jsx";


export function ColumnDataPreview({tableId, columnName}) {
    const {data, loading} = useGetColumnDistributions(tableId, [columnName]);
    const distribution = data ? data[columnName] : null;
    const xMin = minValueFromDistribution(distribution);
    const xMax = maxValueFromDistribution(distribution);
    const chartOptions = {
        showXLabel: true,
        showYLabel: false,
        xMin,
        xMax,
        colors: [CHART_COLORS.lime]
    };

    return (
        <div className="h-[100px] relative mt-8 mb-4">
            {distribution && (
                <div
                    className="ml-[-20px]"
                >
                    <SmoothLineChart
                        height={100}
                        options={chartOptions}
                        data={formatDistributionToLine(distribution)}
                    />
                </div>
            )}
            {loading && (
                <LoadingBlock/>
            )}
        </div>
    );
}

ColumnDataPreview.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired
};

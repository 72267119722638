import {useEffect} from "react";
import {useGetUserEvents} from "src/api/events/useGetUserEvents.js";
import {ClientId, OnboardingEventNames} from "./constants.js";


function isNewFileCompleted(events) {
    return events.filter(({name}) => {
        return name === OnboardingEventNames.FILE_PROCESSED;
    }).length > 0;
}


function isColumnDetailsViewed(events) {
    return events.filter(({name}) => {
        return name === OnboardingEventNames.COLUMN_DETAILS_VIEWED;
    }).length > 0;
}


function isPythonSdkCompleted(events) {
    return events.filter(({name, clientId}) => {
        return name === OnboardingEventNames.DATA_FETCHED && clientId === ClientId.SDK;
    }).length > 0;
}

function isPublicLinkEnabled(events) {
    return events.filter(({name}) => {
        return name === OnboardingEventNames.PUBLIC_LINK_ENABLED;
    }).length > 0;
}

function isInvitedFriendCompleted(events) {
    return events.filter(({name}) => {
        return name === OnboardingEventNames.TABLE_SHARED || name === OnboardingEventNames.FOLDER_SHARED;
    }).length > 0;
}

export function useOnboardingProgress(workspaceId) {
    // eslint-disable-next-line prefer-const
    let {loading, events, stopPolling} = useGetUserEvents();

    if (workspaceId) {
        events = events.filter((e) => e.workspaceId === workspaceId);
    }

    const newFileCompleted = isNewFileCompleted(events);
    const columnDetailsCompleted = isColumnDetailsViewed(events);
    const pythonSdkCompleted = isPythonSdkCompleted(events);
    const publicLinkEnabled = isPublicLinkEnabled(events);
    const invitedFriend = isInvitedFriendCompleted(events);

    const items = [
        newFileCompleted,
        columnDetailsCompleted,
        pythonSdkCompleted,
        publicLinkEnabled,
        invitedFriend
    ];
    const totalItems = items.length;
    const completedItems = items.filter(Boolean).length;

    useEffect(() => {
        if (totalItems === completedItems) {
            console.log("completed all steps");
            stopPolling();
        }
    }, [totalItems, completedItems, stopPolling]);

    return {
        loading,
        events,
        totalItems,
        completedItems,
        steps: {
            newFileCompleted,
            columnDetailsCompleted,
            pythonSdkCompleted,
            publicLinkEnabled,
            invitedFriend
        }
    };
}

import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {isNumber} from "src/utils/validators.js";
import {Callout} from "src/primitives/Callout/index.jsx";
import {SelectOutlierLimit} from "./SelectOutlierLimit.jsx";


export function DefineOutliers({tableId, columnName, outliers, setOutliers}) {
    const updateLowerValue = useCallback((value) => {
        setOutliers((prev) => {
            return {
                ...prev,
                lower: value
            };
        });
    }, [setOutliers]);

    const updateUpperValue = useCallback((value) => {
        setOutliers((prev) => {
            return {
                ...prev,
                upper: value
            };
        });
    }, [setOutliers]);

    return (
        <div className="flex flex-col gap-4">
            <Title size="xs" style={{margin: 0}}>
                Define outliers:
            </Title>
            <div>
                <div className="uppercase text-xxs">
                    Lower limit
                </div>
                <div>
                    <SelectOutlierLimit
                        tableId={tableId}
                        columnName={columnName}
                        value={outliers.lower}
                        onChange={updateLowerValue}
                    />
                </div>
            </div>
            <div>
                <div className="uppercase text-xxs">
                    Upper limit
                </div>
                <div>
                    <SelectOutlierLimit
                        tableId={tableId}
                        columnName={columnName}
                        value={outliers.upper}
                        onChange={updateUpperValue}
                    />
                </div>
            </div>
            <div>
                {(isNumber(outliers.lower) && isNumber(outliers.upper) && outliers.lower > outliers.upper) && (
                    <Callout variant="danger">
                        Lower limit must be less than upper limit
                    </Callout>
                )}
            </div>
        </div>
    );
}

DefineOutliers.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string,
    outliers: PropTypes.shape({
        lower: PropTypes.number,
        upper: PropTypes.number
    }),
    setOutliers: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";

const buttonClasses = cva({
    // eslint-disable-next-line max-len
    base: "px-3 py-1 rounded hover:bg-white/10 disabled:opacity-85 disabled:cursor-not-allowed",
    variants: {
        state: {
            active: "cursor-pointer hover:bg-black/5",
            disabled: "cursor-not-allowed"
        }
    }
});

function getState(isActive, isDisabled = false) {
    if (isDisabled) {
        return "disabled";
    }
    if (isActive) {
        return "active";
    }
    return "";
}


export function TablePagination({currentPage, totalRows, pageSize, onChange}) {
    const totalPages = Math.ceil(totalRows / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onChange(newPage);
        }
    };

    const prevState = getState(false, currentPage === 1);
    const nextState = getState(false, currentPage === totalPages);
    return (
        <div className="flex items-center justify-between border-t border-white/80 mt-2 py-2">
            <div>
                <span className="text-sm opacity-90">
                    Showing {(currentPage - 1) * pageSize + 1} to{" "}
                    {Math.min(currentPage * pageSize, totalRows)} of {totalRows} entries
                </span>
            </div>
            <div className="flex items-center text-sm">
                <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={buttonClasses({state: prevState})}
                >
                    <IconWrapper icon={<FiArrowLeft/>} size="xs" style={{width: "16px", height: "24px"}}/>
                </button>
                <div className="min-w-16 text-center">
                    {currentPage} of {totalPages}
                </div>
                <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={buttonClasses({state: nextState})}
                >
                    <IconWrapper icon={<FiArrowRight/>} size="xs" style={{width: "16px", height: "24px"}}/>
                </button>
            </div>
        </div>
    );
}

TablePagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalRows: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    onChange: PropTypes.func.isRequired
};

TablePagination.defaultProps = {
    pageSize: 10
};

import React from "react";
import PropTypes from "prop-types";
import {ScatterPlot} from "src/charts/ScatterPlot.jsx";
import {Title} from "src/primitives/title.jsx";
import {Histogram} from "src/charts/index.js";
import {CHART_COLORS} from "src/charts/colors.js";


export function ModelResiduals({residuals}) {
    if (!residuals) {
        return null;
    }

    const height = 300;
    const relativeResidualValues = residuals.map((r) => r.relative_residual);

    const predictedRows = residuals.map((r) => [r.y_true, r.y_pred]);
    const relativeResidualRows = residuals.map((r) => [r.y_true, r.relative_residual]);

    return (
        <div className="flex flex-col gap-4">
            <div className="max-w-144">
                <Title size="small">
                    Predicted vs. true values
                </Title>
                <ScatterPlot
                    height={height}
                    data={predictedRows}
                    regression="linear"
                    xName="y_true"
                    yName="y_pred"
                    // xMin={Math.floor(xStats?.p1)}
                    // xMax={Math.ceil(xStats?.p99)}
                    // yMin={Math.floor(yStats?.p1)}
                    // yMax={Math.ceil(yStats?.p99)}
                />
            </div>
            <div className="max-w-144">
                <Title size="small">
                    Relative residuals vs. true values
                </Title>
                <ScatterPlot
                    height={height}
                    data={relativeResidualRows}
                    regression="linear"
                    xName="y_true"
                    yName="relative_residual"
                    // xMin={Math.floor(xStats?.p1)}
                    // xMax={Math.ceil(xStats?.p99)}
                    // yMin={Math.floor(yStats?.p1)}
                    // yMax={Math.ceil(yStats?.p99)}
                />
            </div>
            <div className="max-w-144">
                <Title size="small">
                    Distribution of relative residuals
                </Title>
                <Histogram
                    height={height}
                    values={relativeResidualValues}
                    color={CHART_COLORS.orange}
                />
            </div>
        </div>
    );
}

ModelResiduals.propTypes = {
    residuals: PropTypes.arrayOf(PropTypes.object)
};

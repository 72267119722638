import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation DataTransformationSuggestions($tableId: String!) {
        dataTransformationSuggestions(tableId: $tableId) {
            ok
            errorCode
            result {
                summary
                transformations {
                    columnName
                    transformation
                    comment
                    fromType
                    toType
                }
            }
        }
    }
`;


export function useDataTransformationSuggestions(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {tableId}
        }).then((response) => {
            return response.data.dataTransformationSuggestions;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.dataTransformationSuggestions}];
}

import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_CHAT_SESSIONS_QUERY} from "src/api/chat/useGetChatSessions.js";


const MUTATION = gql`
    mutation StartChat($tableId: String!) {
        startChat(tableId: $tableId) {
            ok
            errorCode
            sessionId
        }
    }
`;


export function useStartChat(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        const refetchQueries = [{query: GET_CHAT_SESSIONS_QUERY, variables: {tableId}}];
        return mutation({
            variables: {tableId},
            refetchQueries
        }).then((response) => {
            return response.data.startChat;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, sessionId: data?.startChat?.sessionId}];
}

import {useState, useCallback} from "react";


function isColumnSelectedFeature(columnName, selectedFeatures) {
    return selectedFeatures.find((item) => item.name === columnName);
}

export function useSelectedFeatures(defaultFeatures) {
    const [selectedFeatures, setSelectedFeatures] = useState(defaultFeatures);

    const handleToggleFeature = useCallback((column) => {
        setSelectedFeatures((prev) => {
            if (isColumnSelectedFeature(column.name, prev)) {
                // remove
                return prev.filter((item) => item.name !== column.name);
            }
            // add
            return [...prev, column];
        });
    }, [setSelectedFeatures]);

    const handleFeatureTransformationChange = useCallback((columnName, transformation) => {
        setSelectedFeatures((prev) => {
            return prev.map((item) => {
                if (item.name === columnName) {
                    return {
                        ...item,
                        transformation
                    };
                }
                return item;
            });
        });
    }, [setSelectedFeatures]);

    const handleFeatureOutliersChange = useCallback((columnName, outliers) => {
        setSelectedFeatures((prev) => {
            return prev.map((item) => {
                if (item.name === columnName) {
                    return {
                        ...item,
                        outliers
                    };
                }
                return item;
            });
        });
    }, [setSelectedFeatures]);

    return [selectedFeatures, {
        setFeatures: setSelectedFeatures,
        toggleFeature: handleToggleFeature,
        setTransformation: handleFeatureTransformationChange,
        setOutliers: handleFeatureOutliersChange
    }];
}

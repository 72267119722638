import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {isLocal} from "src/config.js";


const QUERY = gql`
query GetUserEvents {
  user {
    id
    events {
      id
      userId
      workspaceId
      createdAt
      name
      clientId
      tableId
      data
    }
  }
}
`;


export function useGetUserEvents() {
    const options = {
        fetchPolicy: "cache-and-network",
        // skip polling if we are on localhost
        pollInterval: isLocal() ? null : 10 * 1000
    };
    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery(QUERY, options);

    const events = useMemo(() => {
        return data?.user?.events || [];
    }, [data]);

    return {
        events, loading, error, refetch, startPolling, stopPolling
    };
}

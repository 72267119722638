import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {SelectVisibleColumns} from "src/pages/Home/pages/TablePage/components/SelectVisibleColumns/index.jsx";
import {isLocal} from "src/config.js";
import {SearchTableInput} from "./components/SearchTableInput/index.jsx";
import {ManageFilters} from "./components/ManageFilters/index.jsx";
import {Suggestions} from "./components/Suggestions/index.jsx";


export function TableGridToolbar({table}) {
    return (
        <Wrapper>
            <PrimaryToolbar className="flex items-center gap-4">
                <SearchTableInput table={table}/>
            </PrimaryToolbar>

            <SecondaryToolbar className="flex items-center gap-2">
                {isLocal() && (
                    <Suggestions tableId={table.id}/>
                )}
                <ManageFilters table={table}/>
                <SelectVisibleColumns tableId={table.id} columns={table.columns}/>
            </SecondaryToolbar>
        </Wrapper>
    );
}

TableGridToolbar.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        columns: PropTypes.array
    })
};

const Wrapper = styled.div`
  padding: 16px 16px 16px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PrimaryToolbar = styled.div``;

const SecondaryToolbar = styled.div``;

import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {MODEL_RESULT_FIELDS, MODEL_SETTINGS_FIELDS, PREDICTION_MODEL_FIELDS} from "./fragments.js";


export const GET_PREDICTION_MODEL_QUERY = gql`
    ${PREDICTION_MODEL_FIELDS}
    ${MODEL_RESULT_FIELDS}
    ${MODEL_SETTINGS_FIELDS}
    query GetPredictionModel ($modelId: String!) {
        predictionModel (modelId: $modelId) {
            ...PredictionModelFields
            settings {
                ... ModelSettingsFields
            }
            result {
                ...ModelResultFields
            }
            residuals
        }
    }
`;

export function useGetPredictionModel(modelId) {
    const variables = {modelId};
    const {data, loading, error, refetch} = useQuery(GET_PREDICTION_MODEL_QUERY, {variables});

    const model = useMemo(() => {
        return data?.predictionModel;
    }, [data]);

    return {
        model, loading, error, refetch
    };
}

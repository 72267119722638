import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";


export function PageTitle({children}) {
    return (
        <div className="mb-6 border-b border-black/10">
            <Title>
                {children}
            </Title>
        </div>

    );
}

PageTitle.propTypes = {
    children: PropTypes.node
};

import React from "react";
import PropTypes from "prop-types";
import {coalesce} from "src/utils/misc.js";
import {getPredictBaseUrl} from "../../utils.js";


function constructUrl(baseUrl, params) {
    const searchParams = new URLSearchParams(
        Object.entries(params).map(([key, value]) => [key, value.toString()])
    );
    return `${baseUrl}?${searchParams.toString()}`;
}


export function PredictionUrl({modelId, features, featureData, apiKey}) {
    const params = features.reduce((acc, feature) => {
        return Object.assign(acc, {
            [feature.name]: coalesce(featureData[feature.name], "")
        });
    }, {});
    params.key = apiKey;
    const baseUrl = getPredictBaseUrl(modelId);
    const url = constructUrl(baseUrl, params);

    return (
        <div>
            <div className="monospace text-xs text-blue-600 hover:text-blue-700">
                <a href={url} target="_blank" rel="noreferrer">
                    {url}
                </a>
            </div>
        </div>
    );
}

PredictionUrl.propTypes = {
    modelId: PropTypes.string.isRequired,
    apiKey: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    })),
    featureData: PropTypes.object
};

import React from "react";
import PropTypes from "prop-types";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {GoBackLink} from "src/primitives/navigation/GoBackLink.jsx";


export function ChatSessionHeader({session}) {
    const tableId = useCurrentTableId();
    const getLink = useGetLink();

    return (
        <div className="absolute top-0 left-0 w-full bg-white">
            <div className="relative w-full flex justify-center text-sm border-b border-neutral-100 my-4 pb-3 h-8">
                <div>
                    {session?.title}
                </div>
                <div className="absolute left-0 top-0">
                    <div className="ml-4 mt-[-6px]">
                        <GoBackLink link={getLink(LINKS.tableChat(tableId))}/>
                    </div>
                </div>
            </div>
        </div>
    );
}


ChatSessionHeader.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
    })
};

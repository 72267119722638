import {gql} from "@apollo/client";


export const MODEL_RESULT_FIELDS = gql`
  fragment ModelResultFields on ModelResult {
    r2
    mse
    mae
    mape
  }
`;

export const MODEL_SETTINGS_FIELDS = gql`
  fragment ModelSettingsFields on ModelSettings {
    id
    model
    target
    targetTransformation
    targetOutliers {
        lower
        upper
    }
    features {
        name
        transformation
        outliers {
            lower
            upper
        }
    }
  }
`;


export const PREDICTION_MODEL_FIELDS = gql`
  fragment PredictionModelFields on PredictionModel {
    id
    createdAt
    title
  }
`;

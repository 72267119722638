import React from "react";
import PropTypes from "prop-types";
import {useGetValues} from "src/api/tableData/useGetValues.js";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {ScatterPlot} from "src/charts/ScatterPlot.jsx";
import {LoadingBlock} from "src/primitives/Loader/LoadingBlock.jsx";


function ComparisonChart({tableStats, values, xName, yName, height}) {
    const xStats = tableStats && tableStats[xName];
    const yStats = tableStats && tableStats[yName];
    const xValues = values[xName];
    const yValues = values[yName];

    const rows = xValues.map((val, index) => {
        return [val, yValues[index]];
    });

    return (
        <ScatterPlot
            height={height}
            data={rows}
            xName={xName}
            yName={yName}
            xMin={Math.floor(xStats?.p1)}
            xMax={Math.ceil(xStats?.p99)}
            yMin={Math.floor(yStats?.p1)}
            yMax={Math.ceil(yStats?.p99)}
        />
    );
}

ComparisonChart.propTypes = {
    tableStats: PropTypes.object,
    values: PropTypes.object,
    xName: PropTypes.string,
    yName: PropTypes.string,
    height: PropTypes.number
};


export function ColumnComparison({tableId, targetColumn, featureColumn}) {
    const {data: tableStats, loading: loadingStats} = useGetTableStats(tableId);
    const {data: values, loading: loadingValues} = useGetValues(tableId, [targetColumn, featureColumn]);

    const height = 200;

    return (
        <div className="relative h-[200px]">
            {(loadingStats || loadingValues) && <LoadingBlock/>}
            {(tableStats && values && !loadingValues && !loadingStats) && (
                <ComparisonChart
                    tableStats={tableStats}
                    values={values}
                    xName={featureColumn}
                    yName={targetColumn}
                    height={height}
                />
            )}
        </div>
    );
}

ColumnComparison.propTypes = {
    tableId: PropTypes.string,
    targetColumn: PropTypes.string,
    featureColumn: PropTypes.string
};
